import React, { createContext, MouseEvent, useCallback, useMemo, useState } from 'react';
import Modal from '.';

export interface IContext {
  showModal: (modalContent: any) => void;
  closeModal: (evt: MouseEvent<HTMLButtonElement>) => void;
}

interface IProps {
  children: React.ReactNode | HTMLAllCollection | any;
}

const Context = createContext({} as IContext);
Context.displayName = 'ModalContext';
const { Provider, Consumer } = Context;

const ContextProvider: React.FunctionComponent<IProps> = ({ children }) => {
  const [ show, setShow ] = useState<boolean>(false);
  const [ content, setContent ] = useState<any>();

  const showModal = useCallback((modalContent: any) => {
    setShow(true);
    setContent(modalContent);
  }, [ show ]);

  const closeModal = useCallback((evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    setShow(false);
  }, [ show ]);

  return useMemo(() => (
    <Provider value={{ showModal, closeModal }}>
      {children}
      {show && <Modal>Content: {content}</Modal>}
    </Provider>
  ), [
    children,
    content,
    showModal,
    closeModal,
  ]);
};

export {
  ContextProvider as ModalProvider,
  Consumer as ModalConsumer,
  Context as ModalContext,
}
