import { useCallback, useLayoutEffect, useState } from "react";

interface IHook {
  scrollPosition: number,
}

type HookType = () => IHook;

export const useScrollPosition: HookType = () => {
  const [ scrollPosition, setScrollPosition ] = useState<number>(0);

  const onScrollHandler = useCallback(() => {
    if(window) {
      setScrollPosition(window.scrollY);
    }
  }, []);
   
  useLayoutEffect(() => {
    if(window) {
      window.addEventListener('scroll', onScrollHandler);
    }

    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    }
  }, []);

  return {
    scrollPosition,
  }
};
