import React, { useEffect, useMemo } from 'react';
import { css } from 'styled-components';
import { AppProvider } from './context';
import AppRouter from './router';
import { Div, NavLink, Section } from '../../theme/Elements';
import { pixelToRem } from 'meema.utils';
import { useLocation } from 'react-router-dom';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import { Strong } from '@dan.tovbein/meema-styled-elements';
import { mediumAndUpScreen } from '../../styles/mixins';

const App: React.FunctionComponent<{}> = () => {
  const location = useLocation();
  const { scrollPosition } = useScrollPosition();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [ location.pathname ]);

  return useMemo(() => (
    <>
      <AppProvider>
        <Section
          customCss={css`
            width: 100vw;
            overflow: hidden;
        `}>
          <AppRouter />
        </Section>
        {(scrollPosition > window.innerHeight && location.pathname !== '/contact') && (
          <Div
            customCss={css`
              position: fixed;
              bottom: ${pixelToRem(20)};
              right: -100%;
              box-shadow: 0 ${pixelToRem(4)} ${pixelToRem(20)} rgba(0, 0, 0, 0.25);
              background-color: white;
              transition: all 400ms ease;
              overflow: hidden;
              border-radius: ${pixelToRem(10)};
              
              ${(scrollPosition > 1000) && css`
                right: ${pixelToRem(20)};
              
                ${mediumAndUpScreen(css`
                  right: ${pixelToRem(40)};
                `)}
              `};

              ${mediumAndUpScreen(css`
                bottom: ${pixelToRem(40)};
              `)}
            `}
          >
            <NavLink
              to={`/contact`}
              variant='contained'
              customCss={css`
                color: ${({theme}) => theme.color.secondary.extraDark};
                border-color: transparent;
                padding: ${pixelToRem(20)};
                display: inline-block;
                text-align: center;
                width: 100%;
              `}
            >Quiero un proyecto<br/>exclusivo <Strong>NIDO</Strong></NavLink>
          </Div>
        )}
      </AppProvider>
    </>
  ), [
    location,
    scrollPosition,
  ]);
}

App.displayName = 'App';
export default App;


