import React, { Suspense, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import { Preloader } from '../../components/Widgets';

const Footer = React.lazy(() => import('../../components/Footer'));
const Header = React.lazy(() => import('../../components/Header'));
const Home = React.lazy(() => import('../Home'));
const AboutUs = React.lazy(() => import('../AboutUs'));
const Contact = React.lazy(() => import('../Contact'));
const Projects = React.lazy(() => import('../Projects'));
const Project = React.lazy(() => import('../Project'));

const AppRouter: React.FunctionComponent<{}> = () => {
  return useMemo(() => (
    <React.Fragment>
      <Header />
      <ErrorBoundary fallback='App router error.'>
        <Suspense fallback={<Preloader />}>
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="contact" element={<Contact />} />
              <Route path="projects">
                <Route index element={<Projects/>} />
                <Route path=":projectId" element={<Project/>} />
              </Route>
            </Route>
          </Routes>
        </Suspense>
      </ErrorBoundary>
      <Footer />
    </React.Fragment>
  ), []);
}

export default AppRouter;
