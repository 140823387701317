import { pixelToRem } from 'meema.utils';
import { ITheme } from 'nido';
import {
  fontPrimaryBold,
  fontPrimaryBoldItalic,
  fontPrimaryItalic,
  fontPrimaryRegular,
  fontQuaternaryBold,
  fontQuaternaryItalic,
  fontQuaternaryRegular,
  fontQuinaryItalic,
  fontQuinaryNormal,
  fontSecondaryItalic,
  fontSecondaryRegular,
  fontTertiaryItalic,
  fontTertiaryRegular,
} from './constants';

export const screenSmall = 576;
export const screenMedium = 768;
export const screenLarge = 992;
export const screenExtraLarge = 1200;
export const screenExtraExtraLarge = 1400;

const DefaultTheme: ITheme = {
  color: {
    primary: {
      extraLight: '#F9F5F0',
      light: '#E9E5E0',
      normal: '#DACBB8',
      dark: '#D1C2AF',
      extraDark: '#C7B8A5',
    },
    secondary: {
      extraLight: '#B4AEE8',
      light: '#A09AD4',
      normal: '#8983BD',
      dark: '#59538D',
      extraDark: '#1D1751',
    },
    grayscale: {
      extraLight: '#F0F0F0',
      light: '#F0F0F0',
      normal: '#3D3D3D',
      dark: '#202020',
      extraDark: '#000000',
    },
    success: {
      extraLight: '#F0F0F0',
      light: '#858585',
      normal: '#3D3D3D',
      dark: '#000000',
      extraDark: '#000000',
    },
    warning: {
      extraLight: '#F0F0F0',
      light: '#F0F0F0',
      normal: '#3D3D3D',
      dark: '#000000',
      extraDark: '#000000',
    },
    error: {
      extraLight: '#F0F0F0',
      light: '#F0F0F0',
      normal: '#3D3D3D',
      dark: '#000000',
      extraDark: '#000000',
    },
  },
  text: {
    color: {
      primary: {
        extraLight: '#F0F0F0',
        light: '#F0F0F0',
        normal: '#3D3D3D',
        dark: '#202020',
        extraDark: '#000000',
      },
      secondary: {
        extraLight: '#F9F5F0',
        light: '#E9E5E0',
        normal: '#DACBB8',
        dark: '#D1C2AF',
        extraDark: '#C7B8A5',
      },
      tertiary: {
        extraLight: '#B4AEE8',
        light: '#A09AD4',
        normal: '#8983BD',
        dark: '#59538D',
        extraDark: '#1D1751',
      },
    },
  },
  font: {
    size: {
      _default: pixelToRem(16),
      h1: {
        small: pixelToRem(48),
        medium: pixelToRem(60),
        large: pixelToRem(80),
        extraLarge: pixelToRem(120),
      },
      h2: {
        small: pixelToRem(38),
        medium: pixelToRem(48),
        large: pixelToRem(60),
        extraLarge: pixelToRem(80),
      },
      h3: {
        small: pixelToRem(30),
        medium: pixelToRem(36),
        large: pixelToRem(40),
        extraLarge: pixelToRem(42),
      },
      h4: {
        small: pixelToRem(26),
        medium: pixelToRem(26),
        large: pixelToRem(28),
        extraLarge: pixelToRem(30),
      },
      h5: {
        small: pixelToRem(20),
        medium: pixelToRem(22),
        large: pixelToRem(24),
        extraLarge: pixelToRem(26),
      },
      h6: {
        small: pixelToRem(16),
        medium: pixelToRem(16),
        large: pixelToRem(18),
        extraLarge: pixelToRem(18),
      },
      p: pixelToRem(16),
      span: pixelToRem(16),
      button: pixelToRem(16),
    },
    family: {
      primary: {
        regular: fontPrimaryRegular,
        italic: fontPrimaryItalic,
        bold: fontPrimaryBold,
        boldItalic: fontPrimaryBoldItalic,
      },
      secondary: {
        regular: fontSecondaryRegular,
        italic: fontSecondaryItalic,
      },
      tertiary: {
        regular: fontTertiaryRegular,
        italic: fontTertiaryItalic,
      },
      quaternary: {
        regular: fontQuaternaryRegular,
        italic: fontQuaternaryItalic,
        bold: fontQuaternaryBold,
      },
      quinary: {
        italic: fontQuinaryItalic,
        normal: fontQuinaryNormal,
      },
    }
  },
  header: {
    mobile: {
      backgroundColor: '#F0F0F0',
      minHeight: 100,
    },
    tablet: {
      backgroundColor: '#F0F0F0',
      minHeight: 100,
    },
    desktop: {
      backgroundColor: '#F0F0F0',
      minHeight: 100,
    },
  },
  footer: {
    mobile: {
      backgroundColor: '#F0F0F0',
      minHeight: 656,
    },
    tablet: {
      backgroundColor: '#F0F0F0',
      minHeight: 656,
    },
    desktop: {
      backgroundColor: '#F0F0F0',
      minHeight: 656,
    },
  },
  // https://getbootstrap.com/docs/5.0/layout/breakpoints/
  // $grid-breakpoints: (
  //   xs: 0,
  //   sm: 576px,
  //   md: 768px,
  //   lg: 992px,
  //   xl: 1200px,
  //   xxl: 1400px
  // );
  responsive: {
    screen: {
      extraSmall: {
        minWidth: 0,
        maxWidth: screenSmall - 1,
      },
      small: {
        minWidth: screenSmall,
        maxWidth: screenMedium - 1,
      },
      medium: {
        minWidth: screenMedium,
        maxWidth: screenLarge - 1,
      },
      large: {
        minWidth: screenLarge,
        maxWidth: screenExtraLarge - 1,
      },
      extraLarge: {
        minWidth: screenExtraLarge,
      },
      extraExtraLarge: {
        minWidth: screenExtraExtraLarge,
      },
    },
  },
};

export const LightTheme = {...DefaultTheme};
export const DarkTheme = {...DefaultTheme};

export default DefaultTheme;
