export const fontPrimaryBlack = 'font-primary-black';
export const fontPrimaryBlackItalic = 'font-primary-black';
export const fontPrimaryBold = 'font-primary-bold';
export const fontPrimaryBoldItalic = 'font-primary-bold';
export const fontPrimaryBook = 'font-primary-book';
export const fontPrimaryExtraBold = 'font-primary-extra-bold';
export const fontPrimaryExtraBoldItalic = 'font-primary-extra-bold';
export const fontPrimaryExtraLight = 'font-primary-extra-light';
export const fontPrimaryExtraLightItalic = 'font-primary-extra-light';
export const fontPrimaryItalic = 'font-primary-italic';
export const fontPrimaryLight = 'font-primary-light';
export const fontPrimaryLightItalic = 'font-primary-light';
export const fontPrimaryMedium = 'font-primary-medium';
export const fontPrimaryMediumItalic = 'font-primary-medium-italic';
export const fontPrimaryNormal = 'font-primary-normal';
export const fontPrimaryRegular = 'font-primary-regular';
export const fontPrimarySemiBold = 'font-primary-semi-bold';
export const fontPrimarySemiBoldItalic = 'font-primary-semi-bold-italic';
export const fontPrimaryThin = 'font-primary-thin';
export const fontPrimaryThinItalic = 'font-primary-thin-italic';

export const fontSecondaryBlack = 'font-secondary-black';
export const fontSecondaryBlackItalic = 'font-secondary-black';
export const fontSecondaryBold = 'font-secondary-bold';
export const fontSecondaryBoldItalic = 'font-secondary-bold';
export const fontSecondaryBook = 'font-secondary-book';
export const fontSecondaryExtraBold = 'font-secondary-extra-bold';
export const fontSecondaryExtraBoldItalic = 'font-secondary-extra-bold';
export const fontSecondaryExtraLight = 'font-secondary-extra-light';
export const fontSecondaryExtraLightItalic = 'font-secondary-extra-light';
export const fontSecondaryItalic = 'font-secondary-italic';
export const fontSecondaryLight = 'font-secondary-light';
export const fontSecondaryLightItalic = 'font-secondary-light';
export const fontSecondaryMedium = 'font-secondary-medium';
export const fontSecondaryMediumItalic = 'font-secondary-medium-italic';
export const fontSecondaryNormal = 'font-secondary-normal';
export const fontSecondaryRegular = 'font-secondary-regular';
export const fontSecondarySemiBold = 'font-secondary-semi-bold';
export const fontSecondarySemiBoldItalic = 'font-secondary-semi-bold-italic';
export const fontSecondaryThin = 'font-secondary-thin';
export const fontSecondaryThinItalic = 'font-secondary-thin-italic';

export const fontTertiaryBlack = 'font-tertiary-black';
export const fontTertiaryBlackItalic = 'font-tertiary-black';
export const fontTertiaryBold = 'font-tertiary-bold';
export const fontTertiaryBoldItalic = 'font-tertiary-bold';
export const fontTertiaryBook = 'font-tertiary-book';
export const fontTertiaryExtraBold = 'font-tertiary-extra-bold';
export const fontTertiaryExtraBoldItalic = 'font-tertiary-extra-bold';
export const fontTertiaryExtraLight = 'font-tertiary-extra-light';
export const fontTertiaryExtraLightItalic = 'font-tertiary-extra-light';
export const fontTertiaryItalic = 'font-tertiary-italic';
export const fontTertiaryLight = 'font-tertiary-light';
export const fontTertiaryLightItalic = 'font-tertiary-light';
export const fontTertiaryMedium = 'font-tertiary-medium';
export const fontTertiaryMediumItalic = 'font-tertiary-medium-italic';
export const fontTertiaryNormal = 'font-tertiary-normal';
export const fontTertiaryRegular = 'font-tertiary-regular';
export const fontTertiarySemiBold = 'font-tertiary-semi-bold';
export const fontTertiarySemiBoldItalic = 'font-tertiary-semi-bold-italic';
export const fontTertiaryThin = 'font-tertiary-thin';
export const fontTertiaryThinItalic = 'font-tertiary-thin-italic';

export const fontQuaternaryBlack = 'font-quaternary-black';
export const fontQuaternaryBlackItalic = 'font-quaternary-black';
export const fontQuaternaryBold = 'font-quaternary-bold';
export const fontQuaternaryBoldItalic = 'font-quaternary-bold';
export const fontQuaternaryBook = 'font-quaternary-book';
export const fontQuaternaryExtraBold = 'font-quaternary-extra-bold';
export const fontQuaternaryExtraBoldItalic = 'font-quaternary-extra-bold';
export const fontQuaternaryExtraLight = 'font-quaternary-extra-light';
export const fontQuaternaryExtraLightItalic = 'font-quaternary-extra-light';
export const fontQuaternaryItalic = 'font-quaternary-italic';
export const fontQuaternaryLight = 'font-quaternary-light';
export const fontQuaternaryLightItalic = 'font-quaternary-light';
export const fontQuaternaryMedium = 'font-quaternary-medium';
export const fontQuaternaryMediumItalic = 'font-quaternary-medium-italic';
export const fontQuaternaryNormal = 'font-quaternary-normal';
export const fontQuaternaryRegular = 'font-quaternary-regular';
export const fontQuaternarySemiBold = 'font-quaternary-semi-bold';
export const fontQuaternarySemiBoldItalic = 'font-quaternary-semi-bold-italic';
export const fontQuaternaryThin = 'font-quaternary-thin';
export const fontQuaternaryThinItalic = 'font-quaternary-thin-italic';

export const fontQuinaryBlack = 'font-quinary-black';
export const fontQuinaryBlackItalic = 'font-quinary-black';
export const fontQuinaryBold = 'font-quinary-bold';
export const fontQuinaryBoldItalic = 'font-quinary-bold';
export const fontQuinaryBook = 'font-quinary-book';
export const fontQuinaryExtraBold = 'font-quinary-extra-bold';
export const fontQuinaryExtraBoldItalic = 'font-quinary-extra-bold';
export const fontQuinaryExtraLight = 'font-quinary-extra-light';
export const fontQuinaryExtraLightItalic = 'font-quinary-extra-light';
export const fontQuinaryItalic = 'font-quinary-italic';
export const fontQuinaryLight = 'font-quinary-light';
export const fontQuinaryLightItalic = 'font-quinary-light';
export const fontQuinaryMedium = 'font-quinary-medium';
export const fontQuinaryMediumItalic = 'font-quinary-medium-italic';
export const fontQuinaryNormal = 'font-quinary-normal';
export const fontQuinaryRegular = 'font-quinary-regular';
export const fontQuinarySemiBold = 'font-quinary-semi-bold';
export const fontQuinarySemiBoldItalic = 'font-quinary-semi-bold-italic';
export const fontQuinaryThin = 'font-quinary-thin';
export const fontQuinaryThinItalic = 'font-quinary-thin-italic';
