import React from 'react';
import styled, { css } from "styled-components"
import { CustomCSSType } from '@dan.tovbein/meema-styled-elements';
import { Div, H3, NavLink } from "../../theme/Elements";
import { pixelToRem } from "meema.utils";
import { largeAndUpScreen, mediumAndUpScreen, smallAndLessScreen } from "../../styles/mixins";

export const BorderLine = ({
  styles,
  showInMobile = true,
}: {
  styles?: CustomCSSType;
  showInMobile?: boolean;
}) => {
  return (
    <Div
      customCss={css`
        display: ${showInMobile ? 'block' : 'none'};
        position: relative;
        width: 100%;
        border-top: solid ${pixelToRem(2)} black;

        &:after {
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          border-top: ${pixelToRem(6)} solid transparent;
          border-bottom: ${pixelToRem(6)} solid transparent; 
          border-right: ${pixelToRem(12)} solid black;
          top: -${pixelToRem(7)};
          right: 0;
          content: "";
        }

        ${mediumAndUpScreen(css`
          display: block;
        `)}

        ${styles && styles};
      `}
    />
  );
};

export const Spacer = (
  props: {
    small?: number;
    medium?: number;
    large?: number;
  } = {
    small: 1,
    medium: 1,
    large: 1,
  }
) => {
  return (
    <Div
      className='block-spacer'
      customCss={css`
        display: ${props.small === 0 ? 'none' : 'block'};
        width: 100%;
        height: ${pixelToRem(props.small!)};
        
        ${mediumAndUpScreen(css`
          display: ${props.medium === 0 ? 'none' : 'block'};
          height: ${pixelToRem(props.medium!)};
        `)};
        
        ${largeAndUpScreen(css`
          display: ${props.large === 0 ? 'none' : 'block'};
          height: ${pixelToRem(props.large!)};
        `)};
      `}
    />
  );
};

export const Separator = (
  props: {
    small?: number;
    medium?: number;
    large?: number;
  } = {
    small: 1,
    medium: 1,
    large: 1,
  }
) => {
  return (
    <Div
      class='block-separator'
      customCss={css`
        display: ${props.small === 0 ? 'none' : 'block'};
        width: ${pixelToRem(props.small!)};
        height: ${pixelToRem(2)};
        
        ${mediumAndUpScreen(css`
          display: ${props.medium === 0 ? 'none' : 'block'};
          width: ${pixelToRem(props.medium!)};
        `)};
        
        ${largeAndUpScreen(css`
          display: ${props.large === 0 ? 'none' : 'block'};
          width: ${pixelToRem(props.large!)};
        `)};
      `}
    />
  );
};

export const Preloader = ({
  color = 'primary',
}: {
  color?: 'primary' | 'secondary';
}) => {
  return (
    <Div
      customCss={css`
        display: flex;
        margin: auto;
        justify-content: center;
        padding: ${pixelToRem(10)};

        > * {
          width: ${pixelToRem(20)};
          height: ${pixelToRem(20)};
          border-radius: 50%;
          background: ${(color === 'secondary') ? ({ theme }) => theme.color.secondary.normal : ({ theme }) => theme.color.primary.normal};
          animation-name: animate;
          animation-duration: 1200ms;
          animation-iteration-count: infinite;
          animation-timing-function: cubic-bezier(.41,.42,.69,.67);

          &:nth-child(2) {
            animation-delay: 400ms;
          }

          &:nth-child(3) {
            animation-delay: 600ms;
          }

          &:not(:last-child) {
            margin-right: ${pixelToRem(6)};
          }

          @keyframes animate {
            0% {
              transform: scale(1);
              opacity: 1;
            }
            50% {
              transform: scale(.5);
              opacity: .5;
            }
            100% {
              transform: scale(1);
              opacity: 1;
            }
          }
        }
      `}
    >
      <Div/>
      <Div/>
      <Div/>
    </Div>
  )
}; 

export const ProjectNameAndLink = (
  props: {
    name?: string;
    link?: string;
    headingSize?: {
      small: number;
      medium: number;
      large: number;
    }
  } = {
    name: '',
    link: '',
  }
) => {
  return (
    <Div
      class='block-project-name-and-link'
      customCss={css`
        /* display: flex; */
        /* flex-direction: column; */
        /* flex-basis: 100%; */
        /* flex-shrink: 0; */
        /* height: 50%; */
        /* padding: 0 ${pixelToRem(30)}; */
        /* justify-content: center; */
        /* background: orange; */
        
        ${smallAndLessScreen(css`
          /* order: -1; */
        `)};

        ${mediumAndUpScreen(css``)};


        ${largeAndUpScreen(css`
          /* flex-basis: 40%; */
          /* height: 100%; */
          /* padding: ${pixelToRem(46)}; */
        `)};
      `}
    >
      <Div>
        <H3
          customCss={css`
            line-height: 1.2;
            font-family: ${({ theme }) => theme.font.family.quaternary.regular};
            text-transform: uppercase;
            font-weight: 100;
            letter-spacing: ${pixelToRem(10)};
            
            mark {
              background: transparent;
              font-family: ${({ theme }) => theme.font.family.quaternary.bold};
            }
          `}
        >Proyecto<br/><mark>{props.name}</mark></H3>
        <Spacer small={10} medium={20} large={20} />
        {(props.link) && (
          <NavLink to={`/projects/${props.link}`} variant='text'>VER PROYECTO</NavLink>
        )}
      </Div>
    </Div>
  );
};
