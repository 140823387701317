import React, { createContext, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useScrollPosition } from '../../hooks/useScrollPosition';

type ThemeType = 'light' | 'dark' | 'transparent';

export interface IContext {
  openMenu?: boolean;
  headerHeight: number;
  theme: ThemeType;
  setSectionTheme: React.Dispatch<React.SetStateAction<ThemeType>>;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  showOrHideMenu: (evt: MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
}

interface IProps {
  children: React.ReactNode | HTMLAllCollection | any;
}

const Context = createContext({} as IContext);
Context.displayName = 'HeaderContext';
const { Provider, Consumer } = Context;

const ContextProvider: React.FunctionComponent<IProps> = ({ children }) => {
  const location = useLocation();
  const [ headerHeight, setHeaderHeight ] = useState<number>(120);
  const [ openMenu, setOpenMenu ] = useState<boolean>();
  const { scrollPosition } = useScrollPosition();
  const [ theme, setTheme ] = useState<ThemeType>('light');
  const [ sectionTheme, setSectionTheme ] = useState<ThemeType>('light');
  const [ isInitialPath, setIsInitialPath ] = useState<boolean>(true);

  const showOrHideMenu = useCallback((evt: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    evt.preventDefault();
    setOpenMenu(!openMenu);
  }, [
    openMenu,
  ]);

  useEffect(() => {
    if(!isInitialPath) {
      setOpenMenu(false);
    }
  }, [
    location.pathname,
    setOpenMenu,
  ]);

  useEffect(() => {
    if(scrollPosition > 250) {
      setHeaderHeight(80);
      setTheme('light');
    } else {
      setHeaderHeight(120);
      setTheme(sectionTheme);
    }
  }, [
    scrollPosition,
    sectionTheme,
    theme,
  ]);

  useEffect(() => {
    setIsInitialPath(false);
  }, []);

  return useMemo(() => (
    <Provider value={
      {
        openMenu,
        headerHeight,
        theme,
        setSectionTheme,
        setOpenMenu,
        showOrHideMenu,
      }
    }>
      {children}
    </Provider>
  ), [
    children,
    openMenu,
    isInitialPath,
    headerHeight,
    theme,
    sectionTheme,
    showOrHideMenu,
  ]);
};

export {
  ContextProvider as HeaderProvider,
  Consumer as HeaderConsumer,
  Context as HeaderContext,
}

