import { createGlobalStyle, DefaultTheme } from "styled-components";
import {
  fontPrimaryBold,
  fontPrimaryItalic,
  fontPrimaryRegular,
  fontSecondaryBold,
  fontSecondaryItalic,
  fontSecondaryRegular,
  fontTertiaryItalic,
  fontTertiaryRegular,
  fontQuaternaryRegular,
  fontQuaternaryItalic,
  fontQuaternaryBold,
  fontQuinaryNormal,
  fontQuinaryItalic,
} from "./constants";

import NotoSansItalic from '../fonts/Noto_Sans/NotoSans-Italic.ttf';
import NotoSansRegular from '../fonts/Noto_Sans/NotoSans-Regular.ttf';
import NotoSansBold from '../fonts/Noto_Sans/NotoSans-Bold.ttf';

import NotoSerifItalic from '../fonts/Noto_Serif/NotoSerif-Italic.ttf';
import NotoSerifRegular from '../fonts/Noto_Serif/NotoSerif-Regular.ttf';
import NotoSerifBold from '../fonts/Noto_Serif/NotoSerif-Bold.ttf';

import DMSerifDisplayRegular from '../fonts/DM_Serif_Display/DMSerifDisplay-Regular.ttf';
import DMSerifDisplayRegularItalic from '../fonts/DM_Serif_Display/DMSerifDisplay-Italic.ttf';

import CormorantRegular from '../fonts/Cormorant/Cormorant-Regular.ttf';
import CormorantBold from '../fonts/Cormorant/Cormorant-Bold.ttf';
import CormorantItalic from '../fonts/Cormorant/Cormorant-Italic.ttf';

import PlayfairDisplayNormal from '../fonts/PlayfairDisplay/PlayfairDisplay-Normal.ttf';
import PlayfairDisplayItalic from '../fonts/PlayfairDisplay/PlayfairDisplay-Italic.ttf';

export const GlobalStyle = createGlobalStyle<DefaultTheme>`
  /* Font primary */

  @font-face {
    font-family: ${fontPrimaryRegular};
    src: url(${NotoSansRegular});
  }

  @font-face {
    font-family: ${fontPrimaryItalic};
    src: url(${NotoSansItalic});
  }

  @font-face {
    font-family: ${fontPrimaryBold};
    src: url(${NotoSansBold});
  }

  /* Font secondary */
  @font-face {
    font-family: ${fontSecondaryRegular};
    src: url(${NotoSerifRegular});
  }

  @font-face {
    font-family: ${fontSecondaryItalic};
    src: url(${NotoSerifItalic});
  }


  @font-face {
    font-family: ${fontSecondaryBold};
    src: url(${NotoSerifBold});
  }

  /* Font tertiary */

  @font-face {
    font-family: ${fontTertiaryRegular};
    src: url(${DMSerifDisplayRegular});
    font-weight: 100;
  }

  @font-face {
    font-family: ${fontTertiaryItalic};
    src: url(${DMSerifDisplayRegularItalic});
  }

  /* Font quaternary */

  @font-face {
    font-family: ${fontQuaternaryRegular};
    src: url(${CormorantRegular});
  }

  @font-face {
    font-family: ${fontQuaternaryItalic};
    src: url(${CormorantItalic});
  }
  
  @font-face {
    font-family: ${fontQuaternaryBold};
    src: url(${CormorantBold});
  }
  
  /* Font quinary */

  @font-face {
    font-family: ${fontQuinaryNormal};
    src: url(${PlayfairDisplayNormal});
  }

  @font-face {
    font-family: ${fontQuinaryItalic};
    src: url(${PlayfairDisplayItalic});
  }
  
  body {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${fontPrimaryRegular};
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style-type: none;
  }
`;
