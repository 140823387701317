// import Elements, { CustomCSSType, IElement } from '@bit/meema.ui-components.elements';
import Elements, { IElement, CustomCSSType } from '@dan.tovbein/meema-styled-elements';
import { pixelToRem } from 'meema.utils';
import { NavLink as ReactNavLink, NavLinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { extraExtraLargeAndUpScreen, extraLargeAndUpScreen, largeAndUpScreen, mediumAndUpScreen, smallAndUpScreen } from '../styles/mixins';

export const sharedStyles = css<IElement>`
  ${({customCss}) => customCss && customCss};
`;

type ButtonType = {
  variant?: 'contained' | 'outlined' | 'text';
  customCss?: CustomCSSType;
};

const pStyles = css`
  color: ${({ theme }) => theme.text.color.primary.normal};
  font-size: ${pixelToRem(16)};
  line-height: 2;
  letter-spacing: ${pixelToRem(4)};
  
  ${mediumAndUpScreen(css`
    font-size: ${pixelToRem(16)};
  `)};
  
  ${largeAndUpScreen(css`
    font-size: ${pixelToRem(18)};
  `)};

  ${extraExtraLargeAndUpScreen(css`
    font-size: ${pixelToRem(18)};
    letter-spacing: ${pixelToRem(5)};
  `)};
`;

const linkStyles = css<{
  variant?: 'contained' | 'outlined' | 'text';
  customCss?: CustomCSSType;
}>`
  font-family: ${({theme}) => theme.font.family.quaternary.regular};
  font-size: ${pixelToRem(18)};
  color: ${({theme}) => theme.text.color.primary.normal};
  width: fit-content;
  transition: background-color 200ms ease;
  letter-spacing: ${pixelToRem(3)};
  cursor: pointer;

  ${({ variant }) => variant && css`
    ${variant === 'contained' && css`
      border: solid ${pixelToRem(2)} ${({ theme }) => theme.color.secondary.normal};
      border-radius: ${pixelToRem(8)};
      padding: ${pixelToRem(5)} ${pixelToRem(16)};
    `}

    ${variant === 'outlined' && css`
      border: solid ${pixelToRem(2)} ${({ theme }) => theme.color.secondary.normal};
      border-radius: ${pixelToRem(8)};
      padding: ${pixelToRem(5)} ${pixelToRem(16)};

      &:hover {
        color: #fff;
        background-color: ${({ theme }) => theme.color.secondary.normal};
      }
    `}

    ${variant === 'text' && css`
      text-decoration-color: transparent;
      box-shadow: inset 0 ${pixelToRem(-2)} transparent;
      transition: 250ms cubic-bezier(.5, 0, 0, 1);

      &:hover {
        box-shadow: inset 0 ${pixelToRem(-30)} ${({theme}) => theme.color.primary.extraLight};
        text-decoration-color: ${({theme}) => theme.color.primary.extraLight};
      }
    `}

    ${mediumAndUpScreen(css`
      font-size: ${pixelToRem(18)};

      ${variant === 'text' && css`
        &:hover {
          box-shadow: inset 0 ${pixelToRem(-12)} ${({theme}) => theme.color.primary.extraLight};
        }
      `}
    `)};
  
    ${largeAndUpScreen(css`
      font-size: ${pixelToRem(22)};

      ${variant === 'text' && css`
        &:hover {
          box-shadow: inset 0 ${pixelToRem(-16)} ${({theme}) => theme.color.primary.extraLight};
        }
      `}
    `)};
  `}
`;

export const Div = styled(Elements.Div)`
  ${sharedStyles};
`;

export const Header = styled(Elements.Header)`
  ${sharedStyles};
`;

export const H1 = styled(Elements.H1)`
  color: ${({ theme }) => theme.text.color.primary.normal};
  font-size: ${({ theme }) => theme.font.size.h1.small};
  font-family: ${({theme}) => theme.font.family.quinary.normal};
  /* font-weight: 200; */
  /* font-weight: 100; */
  letter-spacing: 10px;

  ${mediumAndUpScreen(css`
    font-size: ${({ theme }) => theme.font.size.h1.medium};
  `)};
  
  ${largeAndUpScreen(css`
    font-size: ${({ theme }) => theme.font.size.h1.large};
  `)};

  ${extraExtraLargeAndUpScreen(css`
    font-size: ${({ theme }) => theme.font.size.h1.extraLarge};
  `)};

  ${sharedStyles};
`;

export const H2 = styled(Elements.H2)`
  color: ${({ theme }) => theme.text.color.primary.normal};
  font-size: ${({ theme }) => theme.font.size.h2.small};
  /* font-family: ${({theme}) => theme.font.family.tertiary.regular}; */
  font-family: ${({theme}) => theme.font.family.quinary.normal};
  letter-spacing: 10px;

  ${mediumAndUpScreen(css`
    font-size: ${({ theme }) => theme.font.size.h2.medium};
  `)};
  
  ${largeAndUpScreen(css`
    font-size: ${({ theme }) => theme.font.size.h2.large};
  `)};

  ${extraExtraLargeAndUpScreen(css`
    font-size: ${({ theme }) => theme.font.size.h2.extraLarge};
  `)};

  ${sharedStyles};
`;

export const H3 = styled(Elements.H3)`
  color: ${({ theme }) => theme.text.color.primary.normal};
  font-size: ${({ theme }) => theme.font.size.h3.small};
  font-family: ${({theme}) => theme.font.family.quaternary.regular};
  letter-spacing: ${pixelToRem(2)};

  ${mediumAndUpScreen(css`
    font-size: ${({ theme }) => theme.font.size.h3.medium};
  `)};
  
  ${largeAndUpScreen(css`
    font-size: ${({ theme }) => theme.font.size.h3.large};
  `)};

  ${extraExtraLargeAndUpScreen(css`
    font-size: ${({ theme }) => theme.font.size.h3.extraLarge};
  `)};

  ${sharedStyles};
`;

export const H4 = styled(Elements.H4)`
  color: ${({ theme }) => theme.text.color.primary.normal};
  font-size: ${({ theme }) => theme.font.size.h4};
  font-family: ${({theme}) => theme.font.family.quaternary.regular};
  letter-spacing: ${pixelToRem(4)};
  ${sharedStyles};
`;

export const H5 = styled(Elements.H5)`
  color: ${({ theme }) => theme.text.color.primary.normal};
  font-size: ${({ theme }) => theme.font.size.h5};
  font-family: ${({theme}) => theme.font.family.quaternary.regular};
  ${sharedStyles};
`;

export const H6 = styled(Elements.H6)`
  color: ${({ theme }) => theme.text.color.primary.normal};
  font-size: ${({ theme }) => theme.font.size.h6};
  font-family: ${({theme}) => theme.font.family.quaternary.regular};
  /* font-family: ${({ theme }) => theme.font.family.secondary.regular}; */
  ${sharedStyles};
`;

export const P = styled(Elements.P)`
  ${pStyles};
  ${sharedStyles};
`;

export const Span = styled(Elements.Span)`
  color: ${({ theme }) => theme.text.color.primary.normal};
  ${sharedStyles};
`;
export const Img = styled(Elements.Img)`${sharedStyles};`;

export const Nav = styled(Elements.Nav)`${sharedStyles};`;

export const A = styled(Elements.A)`${sharedStyles};`;

export const Button = styled(Elements.Button)`
  background: transparent;
  border: none;
  display: inline-block;

  ${linkStyles};
  ${sharedStyles};
`;

export const Section = styled(Elements.Section)`${sharedStyles};`;

export const Form = styled(Elements.Form)`${sharedStyles};`;

export const Select = styled.select`${sharedStyles};`;

export const Input = styled(Elements.Input)`${sharedStyles};`;

export const Label = styled(Elements.Label)`${sharedStyles};`;

export const Footer = styled(Elements.Footer)`${sharedStyles};`;

export const Figure = styled(Elements.Figure)`${sharedStyles};`;

export const Figcaption = styled(Elements.Figcaption)`${sharedStyles};`;

export const NavLink = styled(ReactNavLink)<ButtonType>`
  ${linkStyles};
  ${({ customCss }) => customCss && customCss};
`;

export const HtmlText = styled(Div)`
  p {
    ${pStyles};
  }
`;

export const View = styled(Elements.Section)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${sharedStyles};
`;

export const Container = styled(Elements.Div)<{ alignFull?: boolean }>`
  width: 100%;
  margin: auto;
  transition: width 150ms ease;

  ${({ alignFull }) => alignFull ? css`

  ` : css`
    ${smallAndUpScreen(css`
      width: ${({ theme }) => pixelToRem(theme.responsive.screen.small.minWidth)};
    `)}
    
    ${mediumAndUpScreen(css`
      width: ${({ theme }) => pixelToRem(theme.responsive.screen.medium.minWidth)};
    `)}

    ${largeAndUpScreen(css`
      width: ${({ theme }) => pixelToRem(theme.responsive.screen.large.minWidth)};
    `)}

    ${extraLargeAndUpScreen(css`
      width: ${({ theme }) => pixelToRem(theme.responsive.screen.extraLarge.minWidth)};
    `)}
    
    ${extraExtraLargeAndUpScreen(css`
      width: ${({ theme }) => pixelToRem(theme.responsive.screen.extraExtraLarge.minWidth)};
    `)}
  `};

  ${sharedStyles};
`;

export const TextContainer = styled(Div)`
  padding: 0 ${pixelToRem(20)};
  
  ${mediumAndUpScreen(css`
    padding-right: 0;
    padding-left: 0;
  `)};
`;

export const InnerContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  padding: 0 ${pixelToRem(20)};
`;

export default {
  A,
  Button,
  Container,
  Div,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Header,
  HtmlText,
  Label,
  P,
  Span,
  Form,
  Footer,
  Figure,
  Figcaption,
  Img,
  Input,
  Nav,
  NavLink,
  Section,
  Select,
  TextContainer,
  InnerContainer,
  View,
}
