import { AxiosResponse } from 'axios';
import { MenuType } from 'nido';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { HeaderProvider } from '../../components/Header/context';
import { ModalProvider } from '../../components/Modal/context';
import MainNavigationService from '../../services/main-navigation';
import { isRequestError } from '../../utils/apiCall';
import Theme from '../../theme/Theme';
import { GlobalStyle } from '../../theme/globalStyle';
import { useScrollPosition } from '../../hooks/useScrollPosition';

export interface IContext {
  menu: Array<MenuType>;
}

interface IProps {
  children: React.ReactNode | HTMLAllCollection | any;
}

const Context = createContext({} as IContext);
Context.displayName = 'AppContext';
const { Provider, Consumer } = Context;

const ContextProvider: React.FunctionComponent<IProps> = ({ children }) => {
  const [ menu, setMenu ] = useState<Array<MenuType>>([]);
  const { scrollPosition } = useScrollPosition();
  
  useEffect(() => {
    (async () => {
      if(menu.length) {
        return;
      }
  
      const result = await MainNavigationService.get();
      let isMounted = false;
        
      if(!isRequestError(result)) {
        setMenu((result as AxiosResponse).data as any);
      }
  
      return () => {
        isMounted = true;
      }
    })();
  }, [ menu ]);

  return useMemo(() => (
    <>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <Provider value={{ menu }}>
          <HeaderProvider>
            <ModalProvider>
              {children}
            </ModalProvider>
          </HeaderProvider>
        </Provider>
      </ThemeProvider>
    </>
  ), [
    menu,
    scrollPosition,
  ]);
};

export {
  ContextProvider as AppProvider,
  Consumer as AppConsumer,
  Context as AppContext,
}
