import React, { useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { css } from 'styled-components';
import { Button, Div } from '../../theme/Elements';
import { ModalContext } from './context';

interface IProps {
  children: React.ReactNode | HTMLAllCollection | any;
}

const Modal: React.FunctionComponent<IProps> = ({
  children,
}) => {
  const { closeModal } = useContext(ModalContext);

  return useMemo(() => (
    <>
      {
        createPortal
        (
          <Div
            customCss={css`
              position: fixed;
              width: 100vw;
              height: 100vh;
              top: 0;
              left: 0;
              background: grey;
            `}
          >
            <Button
              onClick={closeModal}
              customCss={css`
                position: absolute;
                top: 0;
                right: 0;
              `}
            >Cerrar</Button>
            <Div
              customCss={css`
                background: white;
                max-width: 50%;
                max-height: 50%; 
                overflow: hidden;
              `}
            >
              Modal content
              {children && children}
            </Div>
          </Div>,
          document.body
        )
      }
    </>
  ), [
    children,
    closeModal,
  ]);
}

Modal.displayName = 'Modal';
export default Modal;
