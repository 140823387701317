import { AxiosResquestError, IProjectsResponse, ServiceParams } from 'nido';
import { ApiCall } from '../utils/apiCall';

export const get = async (params?: ServiceParams): Promise<IProjectsResponse | AxiosResquestError> => (
  ApiCall({
    baseURL: `${process.env.PUBLIC_URL}/data/main-navigation.json`,
    method: 'GET',
    params: {} as ServiceParams,
  })
);

export default {
  get,
}
